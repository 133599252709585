import React from 'react'
import css from './Hero.module.css'
import HeroImg from '../../assets/hero.png'
import { RiShoppingBagFill } from 'react-icons/ri'
import { BsArrowRight } from 'react-icons/bs'
import { motion } from 'framer-motion'

const Hero = () => {
    const transition = { duration: 3, type: "spring" }

    return (
        <div className={css.container}>
            {/* Left Side */}
            <div className={css.heroSides}>
                <span className={css.text1}>Skin protection cream</span>

                <div className={css.text2}>
                    <span>Trendy Collection</span>
                    <span>Seedily say has suitable disposal & boy. Exercise joy </span>
                </div>
            </div>

            {/* Middle Side Hero Images */}
            <div className={css.wrapper}>

                {/* Blue Circle */}
                <motion.div
                    initial={{ bottom: "2rem" }}
                    whileInView={{ bottom: "0rem" }}
                    transition={transition}

                    className={css.blueCircle}>
                </motion.div>

                {/* Hero Image */}
                <motion.img
                    initial={{ bottom: "-2rem" }}
                    whileInView={{ bottom: "0rem" }}
                    transition={transition}

                    src={HeroImg} alt="" width={600} />
                    
                    {/* Cart div */}
                <motion.div 
                transition={transition}
                initial={{right: "2%"}}
                whileInView={{right: "-6%"}}
                className={css.cart2}>
                    <RiShoppingBagFill />

                    <div className={css.signup}>
                        <span>Best Signup offers</span>
                        <div>
                            <BsArrowRight />
                        </div>
                    </div>
                </motion.div>
            </div>

            {/* Right Side */}
            <div className={css.heroSides}>
                <div className={css.traffic}>
                    <span>1.5m</span>
                    <span>Monthly Traffic</span>
                </div>

                <div className={css.customers}>
                    <span>100k</span>
                    <span>Happy Customers</span>
                </div>
            </div>
        </div>
    )
}

export default Hero